import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node"
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react"
import { useChangeLanguage } from "remix-i18next/react"
import { useTranslation } from "react-i18next"
import { withSentry, captureRemixErrorBoundaryError } from "@sentry/remix"

import i18next from "~/i18next.server"
import stylesheet from "~/tailwind.css?url"
import useVersionChecker from "~/utils/useVersionChecker"
import FielderLogo from "./components/FielderLogo"
import { getUserOrganizationId } from "./utils/session.server"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween.js"
import advancedFormat from "dayjs/plugin/advancedFormat.js"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore.js"
import utc from "dayjs/plugin/utc.js"
import timezone from "dayjs/plugin/timezone.js"
import isoWeek from "dayjs/plugin/isoWeek.js"
import localizedFormat from "dayjs/plugin/localizedFormat.js"
import quarterOfYear from "dayjs/plugin/quarterOfYear.js"
import relativeTime from "dayjs/plugin/relativeTime.js"

dayjs.extend(advancedFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(timezone)
dayjs.extend(isoWeek)
dayjs.extend(localizedFormat)
dayjs.extend(quarterOfYear)
dayjs.extend(relativeTime)
dayjs.extend(utc) // put this at the end, or other plugins *might* overwrite some things that it does

export const links: LinksFunction = () => [
  {
    rel: "icon",
    href: "/favicon.ico",
    sizes: "32x32",
  },
  {
    rel: "icon",
    href: "/favicon.svg",
    type: "image/svg+xml",
  },
  {
    rel: "apple-touch-icon",
    href: "/favicon-192x192.png",
  },
  {
    rel: "manifest",
    href: "/manifest.webmanifest",
  },
  { rel: "stylesheet", href: stylesheet },
  // {
  //   rel: "stylesheet",
  //   href: "https://fonts.googleapis.com/icon?family=Material+Icons",
  // },
]

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = await i18next.getLocale(request)
  const userOrganizationId = await getUserOrganizationId(request)
  return json({
    locale,
    userOrganizationId,
    ENV: {
      APP_VERSION: process.env.APP_VERSION,
      SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
      STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
      GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
    },
  })
}

export let handle = {
  // In the handle export, we can add an i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common",
}

function App() {
  const { locale, ENV } = useLoaderData<typeof loader>()
  const { i18n } = useTranslation()

  // This hook will check if there's a new version of the app and, if so,
  // will force a reload of the page to get the newest code
  useVersionChecker()

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale)

  const googleMapsScript =
    '(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({ key: "' +
    ENV.GOOGLE_MAPS_API_KEY +
    '", v: "weekly" });'

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <script dangerouslySetInnerHTML={{ __html: googleMapsScript }} />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const { t } = useTranslation("apiError")
  const error = useRouteError()

  // Report the error to Sentry
  captureRemixErrorBoundaryError(error)

  console.log("ErrorBoundary: error: ", JSON.stringify(error, null, 2))

  function renderError() {
    if (error.status === 403) {
      return (
        <div className="mx-auto p-20">
          <h1>{t(error.data ?? "not-authorized")}</h1>
          <img src="/images/access-denied.png" alt="Access Denied" />
        </div>
      )
    } else {
      return (
        <div className="mx-auto p-20">
          <h1 className="text-center text-3xl font-bold">
            {t("errorBoundary.title")}
          </h1>
          <p className="text-center text-xl">{error.message}</p>
        </div>
      )
    }
  }

  return (
    <html className="h-full bg-white">
      <head>
        <title>Oops!</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <div className="mx-auto p-20">
          <Link to="/">
            <FielderLogo className="h-12 w-auto" />
          </Link>
          {renderError()}
        </div>
        <Scripts />
      </body>
    </html>
  )
}

export default withSentry(App)
